*, :before, :after {
  box-sizing: border-box;
}

:root {
  --main-header-size: 2rem;
  --main-header-margin-bottom: 3rem;
  --main-content-width: min(70ch, 100vw);
  --secondary-text-color: #686868;
  --theme-color: #2b5797;
  color: #262626;
  background-color: #fbfbfb;
}

@media (prefers-color-scheme: dark) {
  :root {
    --secondary-text-color: #a69f93;
    --theme-color: #7dadda;
    color: #e8e6e3;
    background-color: #1a1c1e;
  }
}

html, body, button, p {
  border: 0;
  margin: 0;
  padding: 0;
}

body {
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12pt;
  display: flex;
}

main, footer {
  width: var(--main-content-width);
  margin-bottom: 1rem;
  padding: 0 1rem;
}

footer {
  color: var(--secondary-text-color);
  text-align: center;
  font-size: .9rem;
}

h1 {
  font-weight: normal;
}

p + p {
  margin-top: 1rem;
}

a {
  color: var(--theme-color);
  text-decoration: none;
}

a:hover, a:active {
  text-underline-position: under;
  text-decoration: underline;
}

noscript {
  color: var(--secondary-text-color);
  margin: 1rem;
}

.text-button {
  --pressed-offset: .05rem;
  color: #fff;
  text-align: center;
  box-shadow: var(--pressed-offset) var(--pressed-offset) var(--pressed-offset) #d3d3d3;
  background-color: #707070;
  border-radius: .5rem;
  padding: .5rem;
}

.text-button:hover, .text-button:active {
  background-color: #4e4e4e;
}

.text-button:active {
  box-shadow: 0 0 var(--pressed-offset) #000;
  transform: translate(var(--pressed-offset), var(--pressed-offset));
}

@media (prefers-color-scheme: dark) {
  .text-button {
    box-shadow: var(--pressed-offset) var(--pressed-offset) var(--pressed-offset) gray;
    background-color: #454a4d;
  }

  .text-button:hover {
    background-color: #565d60;
  }
}
/*# sourceMappingURL=index.66ee23f5.css.map */
