*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --main-header-size: 2rem;
  --main-header-margin-bottom: 3rem;
  --main-content-width: min(70ch, 100vw);
  --secondary-text-color: #686868;
  --theme-color: #2b5797;
  background-color: #fbfbfb;
  color: #262626;

  @media (prefers-color-scheme: dark) {
    --secondary-text-color: #a69f93;
    --theme-color: #7dadda;
    background-color: #1a1c1e;
    color: #e8e6e3;
  }
}

html,
body,
button,
p {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12pt;
  margin-top: 1rem;
}

main,
footer {
  padding: 0 1rem;
  margin-bottom: 1rem;
  width: var(--main-content-width);
}

footer {
  color: var(--secondary-text-color);
  text-align: center;
  font-size: 0.9rem;
}

h1 {
  font-weight: normal;
}

p + p {
  margin-top: 1rem;
}

a {
  text-decoration: none;
  color: var(--theme-color);

  &:hover,
  &:active {
    text-decoration: underline;
    text-underline-position: under;
  }
}

noscript {
  color: var(--secondary-text-color);
  margin: 1rem;
}

.text-button {
  --pressed-offset: 0.05rem;

  padding: 0.5rem;
  color: #ffffff;
  background-color: #707070;
  text-align: center;
  border-radius: 0.5rem;
  box-shadow: var(--pressed-offset) var(--pressed-offset) var(--pressed-offset)
    #d3d3d3;

  &:hover,
  &:active {
    background-color: #4e4e4e;
  }
  &:active {
    box-shadow: 0 0 var(--pressed-offset) #000000;
    transform: translate(var(--pressed-offset), var(--pressed-offset));
  }

  @media (prefers-color-scheme: dark) {
    box-shadow: var(--pressed-offset) var(--pressed-offset)
      var(--pressed-offset) #808080;
    background-color: #454a4d;

    &:hover {
      background-color: #565d60;
    }
  }
}
